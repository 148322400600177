import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../layout/Header";
import ProductList from "../components/Lists/ProductList";
import { getCoupons } from "../service/GetCoupons";
import Footer from "../layout/Footer";
import { LoaderContext } from "../context/LoaderContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Page } from "../context/Pages";

export default function LandingPage() {
  const [coupons, setCoupons] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const { currentPage, setCurrentPage } = useContext(Page);
  const [errorModal, setErrorModal] = useState({ state: false });

  const { loading, setLoading } = useContext(LoaderContext);
  let [totalPages] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    // navigate("/e");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("apiResponse")) {
      setLoading(true);

      handleCoupons();
    } else {
      navigate("/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleResultModalClose = () => {
    setErrorModal({ state: false });
    handleCoupons();
  };

  let isFetching = false;

  const handleCoupons = async () => {
    if (isFetching) {
      // You can choose to wait for the previous call to complete or ignore this call
      return;
    }

    // Set the flag to indicate that data fetching is in progress
    isFetching = true;

    try {
      const response = await getCoupons(currentPage);

      console.log("trestfsghs", response.status);
      if (response?.status === 201) {
        setCoupons(response?.data?.data);
      } else if (response?.status === 200) {
        setCoupons(response?.data?.data.sort((a, b) => b.id - a.id));
      } else {
        setTimeout(() => {
          handleCoupons();
        }, 1000);
        navigate("/");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed
    } finally {
      // Reset the flag to allow the function to be called again
      isFetching = false;
      // Set a timeout to stop loading after a delay (as in your original code)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleNextPage = () => {
    let modular = coupons?.count % 10;
    let devider = (coupons?.count - modular) / 10;
    if (modular) {
      totalPages = devider + 1;
    } else {
      totalPages = devider;
    }
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSort = (e) => {
    const order = e.target.value;
    if (order === "ascending") {
      handleSortAscending();
    } else if (order === "descending") {
      handleSortDescending();
    }
  };

  const handleSortAscending = () => {
    const sortedCoupons = [...coupons].sort((a, b) => a.id - b.id);
    setCoupons(sortedCoupons);
  };

  const handleSortDescending = () => {
    const sortedCoupons = [...coupons].sort((a, b) => b.id - a.id);
    setCoupons(sortedCoupons);
  };

  return (
    <div className="landing-page">
      <Header />
      <div className="content-container">
        <div className="mc-product-list-container">
          <div
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="mc-coupon-page-header"
          >
            <h3 className="total-items" style={{ marginTop: "auto" }}>
              Total Coupons({coupons?.length || coupons?.count || 0})
            </h3>
            <div>
              <select className="select-order" onChange={handleSort}>
                <option value="">Sort By</option>
                <option value="ascending">Oldest First</option>
                <option value="descending" selected>
                  Latest First
                </option>
              </select>
            </div>
          </div>
          <ProductList
            coupons={coupons}
            handleCoupons={handleCoupons}
            currPage={currentPage}
          />
        </div>
      </div>
      <div>
        <div className="mc-list-footer">
          <div className="mc-pagination-page-detail">
            Showing{" "}
            <span className="bold">
              {(currentPage - 1) * 10 + 1 || 0}-
              {(coupons?.rows?.length || coupons?.length) +
                (currentPage - 1) * 10 || 0}
            </span>{" "}
            results
          </div>
          <div className="mc-pagination-buttons-container">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>
              <i class="fa-solid fa-angle-left"></i>
            </button>
            <button>{currentPage}</button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <i class="fa-solid fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
