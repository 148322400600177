import { Button, Modal } from "react-bootstrap";

export function ErrorModal({ show, onClose }) {

    return <Modal
        show={show}
        onHide={onClose}
        centered
    >
        <div className="mc-redeemed-coupon-details">
            <p>We will inform you over WhatsApp{" "}
                <span>
                    <img src="images/whatsapp-icon.png" alt="" width={20} height={20} />
                </span>
                {" "}
                as soon as your voucher is generated. Thank You for your patience.
            </p>
            <div className="mc-product-desc-container description"></div>
        </div>

        {/* <Modal.Body></Modal.Body> */}
        <Modal.Footer className="mc-modal-footer">
            <Button
                className="mc-coupon-redeem-button redeemed-button"
                variant="danger"
                onClick={onClose}
            >
                Ok, got it!
            </Button>
        </Modal.Footer>
    </Modal>
}